import React, { useState } from "react";
import Page from "../../layout/Page";

export default function Medelvarde({ data }) {
    const [value, setValue] = useState("")
    const [text, setText] = useState("")

    function calculate() {
        try {
            const num = Number(value)
            const area = (Math.PI * num * num).toFixed(2)

            setText("Arean är: " + area)
        }

        catch {
            setText("Ett fel inträffade. Kontrollera att du skrivit in rätt i fältet.")
        }
    }


    return (
        <Page title={"Räkna ut area på en cirkel"}>
            <br />
            <br />
            <h1>Räkna ut arean på en cirkel</h1>
            <p>För att beräkna arean och omkretsen av en cirkel används ett tal som heter Pi (π ≈ 3,14). Med π så ges arean av cirkeln genom formeln Area = π x radie^2.</p>
            <p>En cirkel definieras som en rund geometrisk form med en mittpunkt som ligger på ett konstant avstånd från kanten hela vägen runt cirkeln. Det konstanta avståndet mellan mittpunkten och kanten kallas för <em>radie</em> och brukar förkortas <strong>r</strong>. </p>
            <p>Den runda kanten på cirkeln, eller cirkelns kurva, brukar kallas för cirkelns rand eller periferi och består av alla möjliga punkter på ett avstånd av en radie från mittpunkten.</p>
            <h3 id="ber-kna-cirkelns-omkrets-och-area">Beräkna cirkelns area</h3>
            <p>Om du snabbt vill beräkna arean på en cirkel kan du använda vårt verktyg nedan. Skriva in radien för cirkeln i fältet och klicka sedan på knappen “Räkna ut area”.</p>
            <input
                type="number"
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            <button onClick={calculate}>Räkna ut area</button>
            <p>{text}</p>
            <br />
            <br />
        </Page>
    );
}